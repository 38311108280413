import React from 'react'
import styled from 'styled-components'

const images = {
  'willie': require('./../images/testimonials/willie.jpg'),
  'chantal': require('./../images/testimonials/chantal.jpg'),
  'mart': require('./../images/testimonials/mart.jpg'),
  'ernst': require('./../images/testimonials/ernst.jpg'),
  'nienke': require('./../images/testimonials/nienke.jpg')
}

const StyledTestimonial = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  padding: 30px;

  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
`

const Picture = styled.img`
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  margin-right: 30px;
  object-fit: cover;
  border-radius: 50%;
`

const Quote = styled.div`
  flex-grow: 1;
`

const QuoteText = styled.div`
  font-weight: bold;
  font-style: italic;

  span {
    font-style: normal;
  }
`

const QuoteAuthor = styled.div`
  color: #999;
`

const Testimonial = ({ testimonial }) => {
  return (
    <StyledTestimonial>
      <Picture src={images[testimonial.image]} />

      <Quote>
        <QuoteText dangerouslySetInnerHTML={{ __html: testimonial.quote }} />

        <QuoteAuthor>
          &mdash; {testimonial.name}
        </QuoteAuthor>
      </Quote>
    </StyledTestimonial>
  )
}

export default Testimonial
