import React from 'react'
import styled from 'styled-components'

const StyledContentPart = styled.div`
  padding: 30px 20px;

  &.border--bottom {
    border-bottom: 5px dotted #ddd;
  }

  @media (min-width: 600px) {
    padding: 60px 30px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;

    &.italic {
      font-style: italic;
    }

    &.bold {
      font-weight: bold;
    }

    &.light {
      color: #666;
    }

    &.small {
      font-size: .9rem;
    }

    &.center {
      text-align: center;
    }
  }
`

const StyledContentPartInner = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`

const ContentPart = ({ border, children }) => {
  return (
    <StyledContentPart className={[border === 'bottom' ? 'border--bottom' : '']}>
      <StyledContentPartInner>
        {children}
      </StyledContentPartInner>
    </StyledContentPart>
  )
}

export default ContentPart
