import React from 'react'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

const images = {
  'dejeugd': require('./../images/events/dejeugd.jpg'),
  'pinkpop': require('./../images/events/pinkpop.jpg'),
  'awakenings': require('./../images/events/awakenings.jpg'),
  'parkpop': require('./../images/events/parkpop.jpg'),
  'rabbit': require('./../images/events/rabbit.jpg'),
  'wasteland': require('./../images/events/wasteland.jpg'),
  'zwartecross': require('./../images/events/zwartecross.jpg'),
  'dekmantel': require('./../images/events/dekmantel.jpg'),
  'lowlands': require('./../images/events/lowlands.jpg'),
  'intothegreat': require('./../images/events/intothegreat.jpg')
}

const StyledEventCard = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;

  @media (min-width: 600px) {
    padding-bottom: 100%;
  }

  cursor: pointer;
  background-color: #eee;

  &:not(.is-selected) {
    img {
      filter: grayscale(100%) brightness(50%);
      transition: filter .25s ease, transform .25s ease;
    }

    &:hover img {
      filter: grayscale(100%) brightness(75%);
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .75);
    }
  }
`

const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledEventCardInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  padding: 10px;

  color: #fff;
  text-align: center;
  text-shadow: 0 2px 3px #000;

  h3 {
    margin-bottom: 0;
  }
`

const EventCard = ({ event, isSelected }) => {
  return (
    <StyledEventCard className={isSelected ? 'is-selected' : ''}>
      <StyledImg src={images[event.image]} />

      <StyledEventCardInner>
        <h3>{event.name}</h3>

        <p>
          {event.location}
        </p>

        <p>
          {!isSelected && (
            <Button primary type="submit">Selecteer</Button>
          )}
        </p>
      </StyledEventCardInner>
    </StyledEventCard>
  )
}

export default EventCard
