import React, { Fragment, useState, useEffect } from 'react'
import Hero from './components/Hero'
import ContentPart from './components/ContentPart'
import Faq from './components/Faq'
import SignupForm from './components/SignupForm'
import EventSelector from './components/EventSelector'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'

const App = () => {
  const [selectedEvent, setSelectedEvent] = useState(null)

  function onSelectEvent(event) {
    setSelectedEvent(event)
  }

  useEffect(() => {
    if (selectedEvent) {
      const form = document.querySelector('[data-registration-form]')
      setTimeout(() => {
        form.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 150)
    }
  }, [selectedEvent])

  return (
    <Fragment>
      <Hero />
      <ContentPart>
        <h1>Party parents opgelet!</h1>
        <p>
          Je herinnert je de tijden nog vaag: voor je kinderen kreeg zwierf je uren, zo niet dagenlang rond op festivals. Je vergat de tijd, dacht niet aan morgen. Maar nu je een trotse ouder bent, neem je uiteraard je verantwoordelijkheid. Je krijgt er zoveel voor terug..., maar het ouderschap vergt ook pijnlijke offers.
        </p>

        <p>
          Dat is wat ons betreft verleden tijd: want op jouw favoriete festival staat ons kinderdagverblijf! Je neemt de kleine gewoon meer naar het festival, checkt hem direct na de hoofdingang bij ons in en kunt los! Aan het einde haal je je kindje gewoon weer op. Mocht je niet helemaal helder meer zijn, geen probleem: je kleine kan ook blijven logeren. Zo neem je je oogappeltje de volgende ochtend fris en fruitig weer mee!
        </p>

        <p>
          Dus niet meer loten met je partner wie er los mag gaan, of omslachtige logeerpartijtjes organiseren:
        </p>

        <p className="italic bold">
          Je hoeft het feesten niet te minderen, de Partycrèche let op je kinderen!
        </p>

        <p className="italic light small">
          De kosten voor de Partycrèche bedragen € 89 per dag. Voor een overnachting rekenen we € 49 euro. Voor meerdaagse festivals zonder dagkaarten geldt een vast weekendtarief. Een reservering en aanbetaling is vereist. Na een succesvolle intake en betaling wordt je reservering definitief.
        </p>
      </ContentPart>

      <Testimonials />

      <ContentPart>
        <h1>Waar vind je ons?</h1>
        <p>
          Dit jaar hebben we een samenwerking met 10 grote outdoor evenementen. Selecteer hieronder je festival om een aanvraag te doen.
        </p>
      </ContentPart>

      <EventSelector onSelect={onSelectEvent} selectedEvent={selectedEvent} />

      {selectedEvent && (
        <Fragment>
          <span data-registration-form />

          <ContentPart border="bottom">
            <h1>Reserveer</h1>
            <p>
              Vul hier je gegevens in en we nemen binnen een werkdag contact met je op om een tijdstip voor de video-intake af te spreken. Heb je voor jouw hummeltje speciale wensen? Geef ze hieronder aan en we bespreken ze met je tijdens de video-intake. Schroom hierbij niet: wij denken altijd mee over een oplossing die het beste bij jouw situatie past.
            </p>

            <SignupForm selectedEvent={selectedEvent} />
          </ContentPart>
        </Fragment>
      )}

      <ContentPart>
        <h1>Veelgestelde vragen</h1>
        <Faq />
      </ContentPart>

      <Footer />
    </Fragment >
  )
}

export default App
