import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoSvg } from './../images/logo.svg'

const StyledLogoSvg = styled(LogoSvg)`
  max-width: 220px;
  fill: #fff;

  @media (min-width: 600px) {
    max-width: 325px;
  }
`

const Logo = () => <StyledLogoSvg />
export default Logo
