import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

import Testimonial from './Testimonial'
import testimonials from './../testimonials.json'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const StyledTestimonials = styled.div`
  margin: 0 auto;
  padding: 40px 10px;

  background-color: #eee;

  .slick-arrow,
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .slick-list,
  .slick-slide:focus,
  .slick-slide * {
    outline: none !important;
  }

  .slick-dots li button {
    &::before {
      font-size: 15px;
    }
  }
`

const SliderItem = styled.div`
  height: 100%;
  padding: 10px;
`

const Testimonials = () => {
  return (
    <StyledTestimonials>
      <Slider {...sliderSettings}>
        {testimonials.map(testimonial => {
          return (
            <SliderItem key={testimonial.name}>
              <Testimonial testimonial={testimonial} />
            </SliderItem>
          )
        })}
      </Slider>
    </StyledTestimonials>
  )
}

export default Testimonials
