import React from 'react'
import styled from 'styled-components'
import { Button, Select, Form } from 'semantic-ui-react'

const StyledBetaSignup = styled.div`
  padding: 20px 0;
`

const StyledSelectionSummary = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  margin-bottom: 40px;

  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;

  div {
    flex: 0 0 100%;
    padding: 5px;

    @media (min-width: 600px) {
      &:not(:last-child) {
        flex-basis: 50%;
      }
    }
  }
`

const genderOptions = [{
  key: 'jongetje',
  text: 'Jongetje',
  value: 'Jongetje'
}, {
  key: 'girl',
  text: 'Meisje',
  value: 'Meisje'
}, {
  key: 'maaktdatuit',
  text: 'In transitie',
  value: 'In transitie'
}]

const BetaSignup = ({ selectedEvent }) => {
  function onSubmit() {
    window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
  }

  return (
    <StyledBetaSignup>
      <StyledSelectionSummary>
        <div>
          Event: <strong>{selectedEvent.name}</strong><br />
        </div>
        <div>
          Locatie: {selectedEvent.location}
        </div>
        {selectedEvent.weekendOnly && (
          <div>
            Dit festival kent geen dagkaarten.<br />Vast tarief voor 4 nachten en 3 dagen Partycrèche: &euro; 349,-
          </div>
        )}
        {!selectedEvent.weekendOnly && (
          <div>
            Prijs per dag: &euro; 89,-
          </div>
        )}
      </StyledSelectionSummary>

      <Form onSubmit={onSubmit}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Voornaam</label>
            <input type="text" required />
          </Form.Field>

          <Form.Field>
            <label>Achternaam</label>
            <input type="text" required />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>E-mailadres</label>
            <input type="email" required />
          </Form.Field>

          <Form.Field>
            <label>Telefoonnummer</label>
            <input type="tel" required />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>Naam kind</label>
            <input type="text" required />
          </Form.Field>

          <Form.Field>
            <label>Geslacht kind</label>
            <Select placeholder="Selecteer" options={genderOptions} required />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <label>Opmerkingen</label>
          <textarea placeholder="Vermeld hier speciale wensen" />
        </Form.Field>

        <p className="light small italic">
          We leiden je om naar onze betaalomgeving voor de aanbetaling van € 25. Dit is om de kosten van de video-intake te dekken en wordt niet gerestitueerd.
        </p>

        <Form.Group>
          <Form.Field>
            <Button primary type="submit">Intake aanvragen</Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </StyledBetaSignup>
  )
}

export default BetaSignup
