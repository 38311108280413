import React from 'react'
import styled from 'styled-components'
import heroImage from './../images/hero.jpg'

const StyledHeroVideo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledHeroVideoStill = styled.img`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const HeroVideo = () => {
  return (
    <StyledHeroVideo>
      <StyledHeroVideoStill src={heroImage} />
    </StyledHeroVideo>
  )
}
export default HeroVideo
