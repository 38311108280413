import React from 'react'
import styled from 'styled-components'
import HeroVideo from './HeroVideo'
import Logo from './Logo'
import { Icon } from 'semantic-ui-react'

const StyledHero = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vw;

  @media (min-width: 600px) {
    min-height: 100vh;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    content: '';
  }
`

const Foreground = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 30px;

  @media (max-width: 599px) {
    padding: 60px 40px;
  }
`

const ForegroundInner = styled.div`
  max-width: 600px;
`

const Payoff = styled.h1`
  margin: 1rem 0 .5rem;
  padding: 0;

  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  line-height: 1.25;
  color: #fff;
  text-shadow: 0 0 1rem rgba(0, 0, 0, .25);

  .nowrap {
    white-space: nowrap;
  }

  @media (min-width: 600px) {
    font-size: 2rem;
  }
`

const Byline = styled.p`
  margin: 0 0 1rem;
  padding: 0;

  font-family: 'Montserrat', sans-serif;
  font-size: 1.15rem;
  line-height: 1.35;
  color: #fff;
  text-shadow: 0 0 1rem rgba(0, 0, 0, .25);

  @media (min-width: 600px) {
    font-size: 1.25rem;
  }
`

const ArrowDown = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 2rem;
  color: rgba(255, 255, 255, .5);
  transform: translate(-50%, -50%);

  @media (max-width: 599px) {
    display: none;
  }
`

const Hero = () => {
  return (
    <StyledHero>
      <Background>
        <HeroVideo />
      </Background>

      <Foreground>
        <ForegroundInner>
          <Logo />
          <Payoff>
            Met een gerust hart <span className="nowrap">hard gaan</span>
          </Payoff>
          <Byline>
            Jij hoeft het feesten niet te minderen, want wij letten op je kinderen
          </Byline>
        </ForegroundInner>
      </Foreground>

      <ArrowDown>
        <Icon name="chevron down" />
      </ArrowDown>
    </StyledHero>
  )
}

export default Hero
