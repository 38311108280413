import React from 'react'
import styled from 'styled-components'
import events from './../events.json'
import EventCard from './EventCard'

const StyledEventSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledEventSelectorItem = styled.div`
  width: 100%;
  flex: 0 0 100%;

  @media (min-width: 600px) {
    flex: 0 0 50%;
  }

  @media (min-width: 900px) {
    flex: 0 0 33.3%;
  }

  @media (min-width: 1024px) {
    flex: 0 0 25%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 20%;
  }
`

const EventSelector = ({ onSelect, selectedEvent }) => {
  function onClick(event) {
    onSelect(event)
  }

  return (
    <StyledEventSelector>
      {events.map(event => {
        const isSelected = event === selectedEvent

        return (
          <StyledEventSelectorItem key={event.name} onClick={() => onClick(event)}>
            <EventCard event={event} isSelected={isSelected} />
          </StyledEventSelectorItem>
        )
      })}
    </StyledEventSelector>
  )
}

export default EventSelector
