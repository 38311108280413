import React from 'react'
import styled from 'styled-components'
import ContentPart from './../components/ContentPart'
import { Icon } from 'semantic-ui-react'
import { ReactComponent as Zwitsal } from './../images/zwitsal.svg'

const StyledFooter = styled.footer`
  background-color: #eee;
`

const StyledZwitsal = styled(Zwitsal)`
  fill: #999;
`

const StyledLink = styled.a`
  font-size: 2rem;
  color: #999;
  text-decoration: none;
  border: 0;

  &:hover,
  &:focus {
    color: #000;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <ContentPart>
        <p className="center">
          <StyledZwitsal />
        </p>

        <p className="center small">
          Be smart, and <a href="https://celebratesafe.nl/" target="_blank">celebrate safe</a>. En... laat je kindje lekker thuis. Met een goede, betrouwbare oppas.
        </p>

        <p className="center light">
          <StyledLink href="https://www.facebook.com/De-Partycrèche-386534738850484" target="_blank">
            <Icon name="facebook" />
          </StyledLink>

          <StyledLink href="https://instagram.com/departycreche" target="_blank">
            <Icon name="instagram" />
          </StyledLink>
        </p>
      </ContentPart>
    </StyledFooter>
  )
}

export default Footer
