import React from 'react'
import styled from 'styled-components'
import faq from './../faq.json'

const StyledFaqItem = styled.div`
  padding: 20px 0;

  h3 {
    margin-bottom: 10px;
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 20px;
  }
`

const Faq = () => {
  return faq.map(item => {
    return (
      <StyledFaqItem key={item.question}>
        <h3>{item.question}</h3>
        <span dangerouslySetInnerHTML={{ __html: item.answer }} />
      </StyledFaqItem>
    )
  })
}

export default Faq
